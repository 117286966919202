import {Section, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const NotFound: React.FC = () => {
  const {t} = useI18next();
  const title = t('Page not found!');
  return (
    <>
      <SEO title={title} />
      <Section textAlign="center">
        <SectionHeader tagName="h1">{title}</SectionHeader>
      </Section>
    </>
  );
};

export default NotFound;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
